import { Notifier } from 'react-native-notifier'
import Toast from '../components/toast'
import { ComponentProps } from 'react'
import { Platform } from 'react-native'

type Props = Parameters<typeof Notifier.showNotification>[0] &
  Pick<ComponentProps<typeof Toast>, 'variant' | 'type' | 'actions'>

const toast = (
  description: string,
  {
    variant,
    type,
    componentProps = {},
    onPress,
    actions,
    hideOnPress = !onPress && !actions?.length,
    ...props
  }: Props = {}
) => {
  Notifier.showNotification({
    description,
    duration: 6000,
    showAnimationDuration: 500,
    hideAnimationDuration: 500,
    // showEasing: Easing.bounce,
    // onHidden: () => console.log('Hidden'),
    onPress,
    hideOnPress,
    Component: Toast,
    queueMode: 'reset',
    componentProps: {
      ...componentProps,
      variant,
      type,
      actions,
    },
    swipeEnabled: Platform.OS !== 'web',
    // Component: NotifierComponents.Alert,
    // hideAnimationDuration: 400,
    ...props,
  })
}

export default function useToast() {
  return {
    toast,
  }
}
