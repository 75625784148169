import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useFieldFast } from '../../hooks/use-fast-field'
import {
  DateObject,
  DateTimeContainerContext,
  DateTimeObject,
  TimeObject,
} from './context'
import { Sentry } from '@beatgig/helpers/sentry'
import { DateTime } from 'luxon'
import useStable from '@beatgig/design/hooks/use-stable'
import initialFormDateToDateTime from './initial-form-date-to-datetime'
import { ScrollToField } from '../scroll-to-field'

type Props<T> = {
  /**
   * Path to the date field, such as `start_time`.
   *
   * IF AND ONLY IF you know the timezone ahead of time, and you're creating a NEW FORM FROM SCRATCH, your value should be initialized like so:
   *
   * ```js
   * const initialCreateBooking = {
   *   start_time: DateTime.fromObject({
   *        hour: 21,
   *        minute: 0,
   *        second: 0,
   *      })
   *        .setZone(zone, {
   *          keepLocalTime: true,
   *        })
   *        .toJSDate()
   *        .toString()
   * }
   * ```
   *
   * It is CRUCIAL that the timezone is set there, UNLESS it is === to your local timezone.
   *
   * But to be strict, I'm going to FORCE you to initialize with your own timezone.
   *
   * **IF you're passing an ALREADY EXISTENT timestamp, such as from the server, the above is NOT needed.** Just pass the normal form state.
   *
   * It is ONLY when initializing for a given timezone that the above is important.
   */
  name: (string & {}) | keyof T
  /**
   * Path to the timezone name in the form, such as `venue_location.timezone`
   */
  timezone: string
  children: React.ReactNode
}

const dateObjectToDateTime = (datetime: DateTimeObject, timezone: string) => {
  return DateTime.fromObject(datetime).setZone(timezone, {
    // IMPORTANT
    // this lets us give raw time values, turn it into another zone, but preserving the raw values
    keepLocalTime: true,
  })
}

const dateObjectToJSDate = (datetime: DateTimeObject, timezone: string) => {
  return dateObjectToDateTime(datetime, timezone).toJSDate()
}

const initialFormDateToLocalState = (
  formDate: Date | string,
  formTimezone: string,
  name: string
) => {
  if (!formTimezone || !formDate) {
    const error =
      'missing form date or timezone from DateTimeContainer. Make sure to pass the names. Used: ' +
      formTimezone +
      ' for timezone and ' +
      name +
      ' for date name. Make sure both correspond to initial values. \n\nIf you do not know the timezone yet, set it to your local one using DateTime.local().zoneName from luxon.'
    console.error(error)
    Sentry.captureException(error)
  }
  const formDateAsJSDate =
    typeof formDate === 'string' ? new Date(formDate) : formDate
  const timezone = formTimezone

  const normalizedDateWithCorrectTimezone = DateTime.fromJSDate(
    formDateAsJSDate
  )
    // set the timezone (say, to `America/Los_Angeles`)
    // techinically, I believe this step shouldn't be necessary, AS LONG AS the initial state always has a timezone-specified date. Too much to ask?
    // seems like setting the same zone over again doesn't change anything, so I think this line is OK
    .setZone(timezone)
    // convert it back to our timezone
    // for instance, if we originally passed 11am california time, and we're in NYC,
    // we're changing this to 11am NYC time.
    // why? because the picker should show the time as if you're in the destination
    .setZone(DateTime.local().zoneName, {
      // KEEP LOCAL TIME TRUE makes it so that we maintain the intended time from the given timezone
      // if we're in our own timezone, this should do nothing
      // if we're in another timezone, and forget to set a timezone on the initial date, this WILL BREAK
      // values coming from the server should be fine, they already have timezone set
      // just keep an eye out for initial dates
      keepLocalTime: true,
    })

  // VERY IMPORTANT NOTE:
  // when passing an initial value, such as an initial start_time, it MUST be done like this:

  /**
   * IF AND ONLY IF you know the timezone ahead of time, your value should be initialized like so:
   *
   * ```js
   * initialValues = {
   *   start_time: DateTime.fromObject({
   *        hour: 21,
   *        minute: 0,
   *        second: 0,
   *      })
   *        .setZone(zone, {
   *          keepLocalTime: true,
   *        })
   *        .toJSDate()
   *        .toString()
   * }
   * ```
   *
   * It is CRUCIAL that the timezone is set there, UNLESS it is = to your local timezone.
   *
   * But to be strict, I'm going to FORCE you to initialize with your own timezone.
   *
   * IF you're passing an ALREADY EXISTENT timestamp, such as from the server, the above is NOT needed. Just pass the normal form state.
   *
   * It is ONLY when initializing for a given timezone that the above is important.
   */

  // ok, so now we have the values in our own timezone, right?
  // COMMON BUG:
  /**
   * If you initialize the date like this: new Date()
   * ...and the initial timezone like this: `America/Los_Angeles`
   * ...and you ARE NOT in `America/Los_Angeles`, then it WILL BREAK.
   *
   * The initialized date MUST have the timezone associated with it, like mentioned above:
   *
   * `DateTime.fromObject({...}).setZone(timezone)`
   */

  //  ok, let's hopefully do this thing. lol
  const {
    day,
    hour,
    year,
    month,
    minute,
    second,
  } = normalizedDateWithCorrectTimezone

  return { day, hour, year, month, minute, second }
}

export default function DateTimePickerContainer<T extends object>(
  props: Props<T>
) {
  const { timezone: formTimezone, name, children } = props
  //   const [{ value: formTimezone }] = useFieldFast<string>(timezoneName as string)
  const [{ value: formDate }, , { setValue: setFormDate }] = useFieldFast<
    string | Date
  >(name as string)

  const [dateTimeObject, setDate] = useState<
    DateTimeContainerContext['dateTimeObject']
  >(() => {
    return initialFormDateToDateTime(formDate, formTimezone, name as string)
    // return initialFormDateToLocalState(formDate, formTimezone, name as string)

    // if (!formTimezone || !formDate) {
    //   const error =
    //     'missing form date or timezone from DateTimeContainer. Make sure to pass the names. Used: ' +
    //     formTimezone +
    //     ' for timezone and ' +
    //     name +
    //     ' for date name. Make sure both correspond to initial values. \n\nIf you do not know the timezone yet, set it to your local one using DateTime.local().zoneName from luxon.'
    //   console.error(error)
    //   Sentry.captureException(error)
    // }
    // const formDateAsJSDate =
    //   typeof formDate === 'string' ? new Date(formDate) : formDate
    // const timezone = formTimezone

    // const normalizedDateWithCorrectTimezone = DateTime.fromJSDate(
    //   formDateAsJSDate
    // )
    //   // set the timezone (say, to `America/Los_Angeles`)
    //   // techinically, I believe this step shouldn't be necessary, AS LONG AS the initial state always has a timezone-specified date. Too much to ask?
    //   // seems like setting the same zone over again doesn't change anything, so I think this line is OK
    //   .setZone(timezone)
    //   // convert it back to our timezone
    //   // for instance, if we originally passed 11am california time, and we're in NYC,
    //   // we're changing this to 11am NYC time.
    //   // why? because the picker should show the time as if you're in the destination
    //   .setZone(DateTime.local().zoneName, {
    //     // KEEP LOCAL TIME TRUE makes it so that we maintain the intended time from the given timezone
    //     // if we're in our own timezone, this should do nothing
    //     // if we're in another timezone, and forget to set a timezone on the initial date, this WILL BREAK
    //     // values coming from the server should be fine, they already have timezone set
    //     // just keep an eye out for initial dates
    //     keepLocalTime: true,
    //   })

    // // VERY IMPORTANT NOTE:
    // // when passing an initial value, such as an initial start_time, it MUST be done like this:

    // /**
    //  * IF AND ONLY IF you know the timezone ahead of time, your value should be initialized like so:
    //  *
    //  * ```js
    //  * initialValues = {
    //  *   start_time: DateTime.fromObject({
    //  *        hour: 21,
    //  *        minute: 0,
    //  *        second: 0,
    //  *      })
    //  *        .setZone(zone, {
    //  *          keepLocalTime: true,
    //  *        })
    //  *        .toJSDate()
    //  *        .toString()
    //  * }
    //  * ```
    //  *
    //  * It is CRUCIAL that the timezone is set there, UNLESS it is = to your local timezone.
    //  *
    //  * But to be strict, I'm going to FORCE you to initialize with your own timezone.
    //  *
    //  * IF you're passing an ALREADY EXISTENT timestamp, such as from the server, the above is NOT needed. Just pass the normal form state.
    //  *
    //  * It is ONLY when initializing for a given timezone that the above is important.
    //  */

    // // ok, so now we have the values in our own timezone, right?
    // // COMMON BUG:
    // /**
    //  * If you initialize the date like this: new Date()
    //  * ...and the initial timezone like this: `America/Los_Angeles`
    //  * ...and you ARE NOT in `America/Los_Angeles`, then it WILL BREAK.
    //  *
    //  * The initialized date MUST have the timezone associated with it, like mentioned above:
    //  *
    //  * `DateTime.fromObject({...}).setZone(timezone)`
    //  */

    // console.log('[date-time-picker-container]', {
    //   normalizedDateWithCorrectTimezone,
    // })

    // //  ok, let's hopefully do this thing. lol
    // const {
    //   day,
    //   hour,
    //   year,
    //   month,
    //   minute,
    //   second,
    // } = normalizedDateWithCorrectTimezone

    // return { day, hour, year, month, minute, second }
  })

  // unnecessary but who knows lol
  const stableSetFormDate = useStable(setFormDate)

  const stableFormDate = useStable(formDate)

  useEffect(
    function updateFormStateWhenTimezoneOrDatetimeChange() {
      const setFormDate = stableSetFormDate.current

      const nextFormDate = dateObjectToDateTime(dateTimeObject, formTimezone)

      let currentFormDate = stableFormDate.current
      if (typeof currentFormDate === 'string') {
        currentFormDate = new Date(currentFormDate)
      }

      const shouldFormDateUpdate =
        DateTime.fromJSDate(currentFormDate).toMillis() !==
        nextFormDate.toMillis()

      console.log(
        '[date-time-container][provider] might update form state in useEffect',
        {
          currentFormDate,
          shouldFormDateUpdate,
          nextFormDate: nextFormDate.toJSDate(),
          formTimezone,
          dateTimeObject,
        }
      )

      if (shouldFormDateUpdate) {
        console.log('🐮🐮🐮 [date-time-container][provider] DID UPDATE')
        setFormDate(nextFormDate.toJSDate())
      } else {
        console.log('🐽🐽🐽 [date-time-container][provider] DID NOT UPDATE')
      }
    },
    [dateTimeObject, formTimezone, stableFormDate, stableSetFormDate]
  )

  const onChangeDate = useCallback((pickedDate: DateObject) => {
    setDate((currentDateTime) => {
      const nextDateTime = {
        ...currentDateTime,
        ...pickedDate,
      }

      console.log('[date-picker-container] onChangeDate', {
        pickedDate,
        currentDateTime,
        nextDateTime,
      })

      return nextDateTime
    })
  }, [])

  const onChangeTime = useCallback((date: TimeObject) => {
    setDate((currentDateTime) => {
      const nextDateTime = {
        ...currentDateTime,
        ...date,
      }

      return nextDateTime
    })
  }, [])

  const value = useMemo(
    () => ({ dateTimeObject, onChangeDate, onChangeTime }),
    [dateTimeObject, onChangeDate, onChangeTime]
  )

  return (
    <DateTimeContainerContext.Provider value={value}>
      <ScrollToField name={name as string} />
      {children}
      {/* <TimezoneAutocompleteField<T>
        disableEditing
        name={timezoneName}
        dateName={name}
      /> */}
    </DateTimeContainerContext.Provider>
  )
}
