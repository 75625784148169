import { createContext, useContext } from 'react'

export type DateObject = {
  day: number
  month: number
  year: number
}

export type TimeObject = {
  minute: number
  hour: number
  second: number
}

export type DateTimeObject = TimeObject & DateObject

export type DateTimeContainerContext = {
  dateTimeObject: DateTimeObject
  onChangeTime: (date: TimeObject) => void
  onChangeDate: (date: DateObject) => void
}

export const DateTimeContainerContext = createContext<DateTimeContainerContext>(
  null as any // do this so we throw an error if we forget the context in the date picker
  // {
  //   dateTimeObject: {
  //     minute: 0,
  //     hour: 0,
  //     second: 0,
  //     day: 0,
  //     month: 0,
  //     year: 0,
  //   },
  //   onChangeDate: () => {
  //     throw new Error('[Date-Time-Context-Container] missing context')
  //   },
  //   onChangeTime: () => {
  //     throw new Error('[Date-Time-Context-Container] missing context')
  //   },
  // }
)

export const useDateTimeFieldContext = () =>
  useContext(DateTimeContainerContext)
