import type { NotifierComponents } from 'react-native-notifier'
import React, { ComponentProps } from 'react'
import { View, Text, useDripsyTheme } from 'dripsy'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Card from '../card'
import WebHeaderSpacer from '@beatgig/components/web-header/spacer'
import { Variants } from '../types'
// TODO move this to /design
import useResponsive from '@beatgig/hooks/use-responsive'
import Button from '@beatgig/components/button'
import { Notifier } from 'react-native-notifier'
import HoverPulse from '@beatgig/components/hover-pulse/index'

type Props = ComponentProps<typeof NotifierComponents['Notification']> &
  ComponentProps<typeof Card> & {
    variant?: Variants
    type?: 'filled' | 'faded' | 'outline'
    actions?: (Omit<ComponentProps<typeof Button>, 'children'> & {
      label: React.ReactNode | string
      shouldCloseNotification?: boolean
    })[]
  }

export default function Toast({
  actions,
  description,
  type,
  variant = 'primary',
}: Props) {
  const { colors } = useDripsyTheme().theme
  const { top, bottom } = useSafeAreaInsets()

  const getTextColor = () => {
    let color = 'text'
    if (variant === 'primary' || variant === 'success') {
      color = 'background'
    }
    if (type === 'outline') {
      color = colors?.[variant] as string
    } else if (type === 'faded') {
      color = colors?.[variant] as string
    }
    return color
  }

  const color = getTextColor()

  const { vw } = useResponsive()

  return (
    <View
      sx={{
        mt: top,
        mb: bottom,
        width: vw(100),
        maxWidth: [null, 420],
        px: 2,
        // alignSelf: 'center',
      }}
      pointerEvents="box-none"
    >
      <WebHeaderSpacer {...{ bg: 'transparent' }} />
      <HoverPulse>
        <Card
          sx={{
            my: [2, 3],
          }}
          variant={variant}
          type={type}
        >
          <Card.Content
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              // p: actions?.length ? 2 : 3,
            }}
          >
            <Text sx={{ color, fontWeight: '600' }}>{description}</Text>
            {!!actions?.length && (
              <View sx={{ flexDirection: 'row' }}>
                {actions.map(
                  (
                    {
                      sx = {},
                      variant,
                      label,
                      onPress,
                      shouldCloseNotification = true,
                      ...button
                    },
                    i
                  ) => (
                    <Button
                      key={i.toString()}
                      {...button}
                      variant={variant || 'smallOutlined'}
                      onPress={
                        onPress &&
                        ((e) => {
                          if (typeof onPress === 'function') {
                            e.preventDefault()
                            onPress(e)
                            if (shouldCloseNotification) {
                              Notifier.hideNotification()
                            }
                          }
                        })
                      }
                      sx={{
                        fontSize: 1,
                        px: 2,
                        py: 1,
                        borderRadius: 3,
                        ml: i ? 2 : 0,
                        borderWidth: 1,
                        color: 'text',
                        bg: 'background',
                        borderColor: 'background',
                        ...sx,
                      }}
                    >
                      {label}
                    </Button>
                  )
                )}
              </View>
            )}
          </Card.Content>
        </Card>
      </HoverPulse>
    </View>
  )
}
