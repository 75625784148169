import { useMemo } from 'react'
import type * as Urql from 'urql'

import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'

type QueryArgs<Variables, Data> = Omit<
  Urql.UseQueryArgs<Variables, Data>,
  'query'
>

const header = 'Admin-On-Behalf-Of-User'

export function getAdminOnBehalfOfUidFromOperation<Data, Variables>(
  operation?: Urql.Operation<Data, Variables>
): string | undefined {
  let fetchOptions = operation?.context?.fetchOptions
  fetchOptions =
    typeof fetchOptions == 'function' ? fetchOptions() : fetchOptions

  return fetchOptions?.headers?.[header]
}

export function adminOnBehalfOfHeaders(onBehalfOf: string) {
  return {
    [header]: onBehalfOf,
  }
}

export function adminOnBehalfOfContext<Variables, Data>(
  onBehalfOf: string,
  context: QueryArgs<Variables, Data>['context'] = {}
): QueryArgs<Variables, Data>['context'] {
  return {
    ...context,
    fetchOptions() {
      const fetchOptions =
        typeof context?.fetchOptions == 'function'
          ? context.fetchOptions()
          : context?.fetchOptions
      const headers = {
        ...context?.headers,
        ...adminOnBehalfOfHeaders(onBehalfOf),
      }

      return {
        ...fetchOptions,
        headers,
      }
    },
  }
}

type RequiredField<Obj, Field extends keyof Obj> = Omit<Obj, Field> &
  Required<Pick<Obj, Field>>

export function useAdminOnBehalfOf<
  Variables,
  Data,
  Returns extends Variables extends undefined
    ? QueryArgs<Variables, Data>
    : RequiredField<
        QueryArgs<Variables, Data>,
        'variables'
      > = Variables extends undefined
    ? QueryArgs<Variables, Data>
    : RequiredField<QueryArgs<Variables, Data>, 'variables'>
>(
  onBehalfOf: string | null,
  options: QueryArgs<Variables, Data> = {}
): Returns {
  const context = useMemo<QueryArgs<Variables, Data>['context']>(() => {
    if (!onBehalfOf) {
      return options.context
    }
    return adminOnBehalfOfContext(onBehalfOf, options.context)
  }, [onBehalfOf, options.context])

  const isAdmin = User.isAdmin(useMyAccount().data)
  if (isAdmin) {
    // we want to avoid race conditions where the admin sees the wrong user
    // this is required since the variables will be the same for multiple calls
    options.requestPolicy = 'network-only'
  }

  return {
    ...options,
    context,
  } as Returns
}
