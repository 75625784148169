import timezones from './time-zones'

export const getDisplayTimezone = (zone: string) => {
  const timezone = timezones.find(
    ({ name, group }) =>
      // we're either in the timezone
      zone === name ||
      // or a timezone group (for example, America/Detroit is in the America/New York group)
      group?.includes(zone)
  )

  return timezone
}
