import React from 'react'
import { View } from 'dripsy'

import type { Sx as SxStyleProp } from 'dripsy'
import type { DripsyTheme } from '@beatgig/theme'

interface Props {
  disableAutoMargin?: boolean
  sx?: SxStyleProp
  bg?: keyof DripsyTheme['colors']
}

const defaultProps: Props = {
  disableAutoMargin: false,
  sx: {},
}

type NativeAttrs = Omit<Partial<React.ComponentProps<typeof View>>, keyof Props>
export type CardFooterProps = Props & typeof defaultProps & NativeAttrs

// https://github.com/geist-org/react/blob/master/components/card/card-footer.tsx
// TODO add global styles for its text, margins, etc.
const CardFooter: React.FC<React.PropsWithChildren<CardFooterProps>> = ({
  children,
  sx,
  disableAutoMargin,
  bg,
  ...props
}) => {
  return (
    <View
      sx={(theme) => ({
        paddingY: theme?.space?.[2] * 1.5 + 0.000000000001,
        paddingX: 3,
        flexDirection: 'row',
        alignItems: 'center',
        borderTopWidth: '1',
        borderTopColor: 'border',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        // minHeight: (theme: Theme) => theme?.space?.[3] * 2.5 + 0.000000000001,
        // bg: 'muted',
        bg,
        ...sx,
      })}
      {...props}
    >
      {children}
    </View>
  )
}

CardFooter.defaultProps = defaultProps

export default CardFooter
