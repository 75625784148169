import { Sentry } from '@beatgig/helpers/sentry'
import { DateTime } from 'luxon'

export default function initialFormDateToDateTime(
  formDate: Date | string,
  formTimezone: string,
  name: string
) {
  if (!formTimezone || !formDate) {
    const error =
      'missing form date or timezone from DateTimeContainer. Make sure to pass the names. Used: ' +
      formTimezone +
      ' for timezone and ' +
      name +
      ' for date name. Make sure both correspond to initial values. \n\nIf you do not know the timezone yet, set it to your local one using DateTime.local().zoneName from luxon.'
    console.error(error)
    Sentry.captureException(error)
  }
  const formDateAsJSDate =
    typeof formDate === 'string' ? new Date(formDate) : formDate
  const timezone = formTimezone

  const normalizedDateWithCorrectTimezone = DateTime.fromJSDate(
    formDateAsJSDate
  )
    // set the timezone (say, to `America/Los_Angeles`)
    // techinically, I believe this step shouldn't be necessary, AS LONG AS the initial state always has a timezone-specified date. Too much to ask?
    // seems like setting the same zone over again doesn't change anything, so I think this line is OK
    .setZone(timezone)
    // convert it back to our timezone
    // for instance, if we originally passed 11am california time, and we're in NYC,
    // we're changing this to 11am NYC time.
    // why? because the picker should show the time as if you're in the destination
    .setZone(DateTime.local().zoneName, {
      // KEEP LOCAL TIME TRUE makes it so that we maintain the intended time from the given timezone
      // if we're in our own timezone, this should do nothing
      // if we're in another timezone, and forget to set a timezone on the initial date, this WILL BREAK
      // values coming from the server should be fine, they already have timezone set
      // just keep an eye out for initial dates
      keepLocalTime: true,
    })

  // VERY IMPORTANT NOTE:
  // when passing an initial value, such as an initial start_time, it MUST be done like this:

  /**
   * IF AND ONLY IF you know the timezone ahead of time, your value should be initialized like so:
   *
   * ```js
   * initialValues = {
   *   start_time: DateTime.fromObject({
   *        hour: 21,
   *        minute: 0,
   *        second: 0,
   *      })
   *        .setZone(zone, {
   *          keepLocalTime: true,
   *        })
   *        .toJSDate()
   *        .toString()
   * }
   * ```
   *
   * It is CRUCIAL that the timezone is set there, UNLESS it is = to your local timezone.
   *
   * But to be strict, I'm going to FORCE you to initialize with your own timezone.
   *
   * IF you're passing an ALREADY EXISTENT timestamp, such as from the server, the above is NOT needed. Just pass the normal form state.
   *
   * It is ONLY when initializing for a given timezone that the above is important.
   */

  // ok, so now we have the values in our own timezone, right?
  // COMMON BUG:
  /**
   * If you initialize the date like this: new Date()
   * ...and the initial timezone like this: `America/Los_Angeles`
   * ...and you ARE NOT in `America/Los_Angeles`, then it WILL BREAK.
   *
   * The initialized date MUST have the timezone associated with it, like mentioned above:
   *
   * `DateTime.fromObject({...}).setZone(timezone)`
   */

  //  ok, let's hopefully do this thing. lol
  const {
    day,
    hour,
    year,
    month,
    minute,
    second,
  } = normalizedDateWithCorrectTimezone

  return { day, hour, year, month, minute, second }
}
