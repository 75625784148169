import React, { useRef, useMemo } from 'react'
import { ScrollablesContext } from './scrollables-context'
import { TextInput } from 'react-native'

type Props = {
  children: ((props: ScrollablesContext) => React.ReactNode) | React.ReactNode
}

/**
 * Make this the parent of Formik. Then in onSubmit, if there are errors, you can call scrollToFirstInputFromNamesWebOnly().
 *
 * Next, inside of the TextInput, pass `registerRef` to the TextInput:
 *
 * ```jsx
 * const name = 'user.profile_image'
 *
 * <TextInput ref={inputRef => registerRef(name, 'input', inputRef)} />
 * ```
 */
export default function ScrollablesProvider({ children }: Props) {
  const inputRefs = useRef<Record<string, TextInput>>({})

  const value = useMemo<ScrollablesContext>(() => {
    const __findFirstInputFromNames = (names: string[]) => {
      const possibleInputRefs: typeof inputRefs.current = {}

      Object.entries(inputRefs.current).forEach(([name, ref]) => {
        if (names.includes(name)) {
          possibleInputRefs[name] = ref
        }
      })

      const firstInputRef = Object.keys(possibleInputRefs)[0]

      return firstInputRef
    }

    const __scrollToInputWebOnly = (name: string) => {
      if (name && inputRefs.current[name]) {
        inputRefs.current[name].focus()
      }
    }

    const scrollToFirstInputFromNamesWebOnly = (names: string[]) => {
      const firstInputToScrollTo = __findFirstInputFromNames(names)

      if (firstInputToScrollTo) {
        __scrollToInputWebOnly(firstInputToScrollTo)
      }
    }

    return {
      __inputRefs: inputRefs,
      __scrollToInputWebOnly,
      __findFirstInputFromNames,
      registerRef: (name, type, ref) => {
        if (type === 'input' && ref) {
          inputRefs.current[name] = ref
        }
      },
      scrollToFirstInputFromNamesWebOnly,
      maybeScrollToFirstInputFromNamesWebOnly: scrollToFirstInputFromNamesWebOnly,
    }
  }, [])

  return (
    <ScrollablesContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ScrollablesContext.Provider>
  )
}
