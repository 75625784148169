import { Artist } from '@beatgig/api-services'
import { makeArtistSlug } from './artist-routes'
import { NativeStackParams } from '../stacks/native-stack/types'
import { stringify } from 'querystring'
import { NativeTabsParams } from '../tabs/native-tabs/types'

const PublicRoutes = {
  venue: ({ slug }: { slug: string }) =>
    ({
      routeName: 'venue' as keyof NativeStackParams,
      web: { path: `/venues/[slug]`, as: `/venues/${slug}` },
      params: { slug },
    } as const),
  events: () =>
    ({
      routeName: 'events',
    } as const),
  artist: ({ slug, artist }: { slug: string; artist?: Artist }) =>
    ({
      routeName: 'Artist' as keyof NativeStackParams,
      web: {
        path: `/artists/[slug]`,
        as: `/${makeArtistSlug(slug)}`,
        prefetch: false,
      },
      params: {
        slug,
      },
      // native: {
      //   navigationBehavior: 'push',
      //   isNativeStack: true,
      // },
    } as const),
  home: () =>
    ({
      routeName: '/',
      web: {
        path: '/',
      },
    } as const),
  discover() {
    const routeName: keyof NativeTabsParams = 'discover'
    return {
      routeName,
      web: {
        path: '/discover',
      },
    } as const
  },
  marketingPage: (product: 'artists' | 'colleges' | 'venues') => {
    return {
      web: { path: `/products/${product}` },
      routeName: `/products/${product}`,
    } as const
  },
  help: ({
    message,
    userId,
  }: NativeStackParams['LiveChat'] & { userId?: string } = {}) => {
    const routeName: keyof NativeStackParams = 'LiveChat'
    const params = message || userId ? `?${stringify({ message, userId })}` : ''
    return {
      web: {
        path: `/help${params}`,
      },
      routeName,
    } as const
  },
}

export default PublicRoutes
