import { ServerError } from '@beatgig/api-services'
import { FormikProps } from 'formik'
import { Sentry } from '@beatgig/helpers/sentry'
import { ScrollableFormProps } from '../context/scrollables/scrollables-context'

const handleServerFormError = <Form extends any>({
  formName,
  error,
  setFieldError,
  scrollToFirstInputFromNamesWebOnly,
  sendToSentry,
  setError,
}: {
  formName: string
  error: ServerError
  setFieldError: FormikProps<Form>['setFieldError']
  sendToSentry?: boolean
  setError?: (error: ServerError) => void
} & ScrollableFormProps) => {
  const fieldErrors = error && 'body' in error && error?.body?.detail

  setError?.(error)

  if (fieldErrors) {
    // FIXME gql add error handling for graphql error paths too
    const errorNames: string[] = []

    fieldErrors.forEach((fieldError) => {
      // omit the body field
      if (fieldError?.loc?.[0] === 'body') {
        const [_, ...fields] = fieldError.loc
        if (fields.length && fieldError.msg) {
          const path = fields.join('.')
          setFieldError(path, fieldError.msg)

          errorNames.push(path)
        }
      }
    })

    scrollToFirstInputFromNamesWebOnly(errorNames)

    if (sendToSentry) {
      Sentry.captureEvent({
        message: formName + ' server form validation error',
        extra: {
          fieldErrors,
        },
      })
    }
  }

  const gqlErrors = error && 'graphQLErrors' in error && error.graphQLErrors

  if (gqlErrors) {
    const errorNames: string[] = []
    gqlErrors.forEach(({ path = [] }) => {
      const [root, ...fields] = path

      if (fields.length) {
        const fieldPath = fields.join('.')
        errorNames.push(fieldPath)
      }
    })

    scrollToFirstInputFromNamesWebOnly(errorNames)
  }
}

export default function useServerFormError() {
  return {
    handleServerFormError,
  }
}
