import React, { useMemo } from 'react'
import { View } from 'dripsy'
import { FieldName } from '../types'
import DateTimePicker from '@beatgig/components/date-time-picker'
import { useFormikContext } from 'formik'
import ErrorText from '../error'
import FieldLabel from '../field-label'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { Object } from 'ts-toolbelt'
import { DateTime } from 'luxon'

import { useFieldFast } from '../../hooks/use-fast-field'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import { useDateTimeFieldContext } from './context'

export type DateTimeContextPickerFieldProps<T extends object> = {
  name: FieldName<T>
  label?: string
  sx?: React.ComponentProps<typeof View>['sx']
  onFocus?: () => void
  onBlur?: () => void
  validationEvent?: 'submit' | 'touch'
  required?: boolean
  labelPosition?: 'top' | 'left' | 'right'
  tooltip?: string | React.ReactNode
  /**
   * If `true`, hides error message. Useful if you have the same date for both time and date.
   */
  hideErrors?: boolean
} & Object.Omit<
  React.ComponentProps<typeof DateTimePicker>,
  'date' | 'onConfirm' | 'onCancel'
>

export default function DateTimeContextPickerField<T extends object>(
  props: DateTimeContextPickerFieldProps<T>
) {
  const {
    name: nameProp,
    // inputProps = {},
    label,
    sx = {},
    required = false,
    labelPosition = 'top',
    tooltip,
    mode,
    hideErrors = false,
    ...pickerProps
    // ...fieldProps
  } = props
  const { submitCount } = useFormikContext()
  const { ref, isHovered } = useAnimatedHover()
  const name = Array.isArray(nameProp) ? nameProp.join('.') : nameProp
  const [, { error, touched }] = useFieldFast<Date | null | string>(
    name as string
  )

  const {
    dateTimeObject,
    onChangeDate,
    onChangeTime,
  } = useDateTimeFieldContext()

  const hasErrored = !!error && !!(touched || submitCount)

  const onSelectDate = (selectedDate?: Date | null) => {
    if (selectedDate) {
      const dateTime = DateTime.fromJSDate(selectedDate)
      if (mode === 'date') {
        const year = dateTime.year
        const month = dateTime.month
        const day = dateTime.day

        onChangeDate({
          year,
          month,
          day,
        })
      } else if (mode === 'time') {
        const hour = dateTime.hour
        const minute = dateTime.minute
        const second = dateTime.second

        onChangeTime({
          hour,
          minute,
          second,
        })
      }
    }
  }

  const displayDate = useMemo(
    // nothing fancy here. The dipslay date should use raw values,
    // as if it were our local time. It's only on the changed times that we then mutate the form state
    // but the picker will always be showing/producing dummy strings
    () => {
      const date = DateTime.fromObject(dateTimeObject).toJSDate()
      return date
    },
    [dateTimeObject]
  )

  return (
    <View ref={ref} sx={sx}>
      {!!label && (
        <FieldLabel
          isHovered={isHovered}
          hasErrored={hasErrored}
          required={required}
        >
          {label}
        </FieldLabel>
      )}
      <HoverTooltip text={tooltip} bg="text" placement="top" anchor="right">
        <DateTimePicker
          {...pickerProps}
          mode={mode}
          date={displayDate}
          onCancel={onSelectDate}
          onConfirm={onSelectDate}
          headerTextIOS={label}
          error={hasErrored}
        />
      </HoverTooltip>
      {!hideErrors && hasErrored && !!error?.trim() && (
        <ErrorText>{error}</ErrorText>
      )}
    </View>
  )
}
