import React from 'react'
import { View, Text, useDripsyTheme } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import type { DripsyTheme } from '@beatgig/theme'

type Props = {
  long?: string
  text: string
  textSx?: SxStyleProp
  /**
   * Default: `200`
   */
  characterLimit?: number
  initiallyExpanded?: boolean
  showMoreColor?: keyof DripsyTheme['colors']
  /**
   * Default: `Show more`
   */
  showMoreText?: string
  /**
   * Default: `Show less`
   */
  showLessText?: string
  showMoreIconColor?: string
  showDots?: boolean
} & React.ComponentProps<typeof Text>

export default function ShowMoreText(props: Props) {
  const {
    sx = {},
    text,
    textSx = {},
    long,
    characterLimit = 200,
    initiallyExpanded = false,
    // numberOfLines = 3,
    showMoreColor = 'accent',
    showMoreText = 'Show more',
    showLessText = 'Show less',
    showMoreIconColor = showMoreColor,
    showDots = true,
  } = props

  const canExpand = !!((long && long > text) || text?.length > characterLimit)
  const [isShowingMore, setIsShowingMore] = React.useState(initiallyExpanded)
  const { colors } = useDripsyTheme().theme

  const toggle = () => setIsShowingMore((state) => !state)

  let finalText = text ?? ''

  if (isShowingMore) {
    if (long) {
      finalText = long
    }
  } else if (canExpand) {
    finalText = text?.slice(0, characterLimit)
    if (finalText) {
      // make sure we don't cut the last word off
      const words = finalText.split(' ')
      words.pop()
      finalText = words.join(' ')
      if (showDots) {
        finalText = `${finalText}...`
      }
    }
  }

  const renderShowMore = () => {
    if (!canExpand) return null

    return (
      <View
        sx={{
          // pl: isShowingMore ? 2 : 0,
          mt: 2,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          selectable={false}
          onPress={toggle}
          sx={{ ...textSx, color: showMoreColor }}
        >
          {isShowingMore ? showLessText : showMoreText}{' '}
          <Ionicons
            name={isShowingMore ? 'chevron-up' : 'chevron-down'}
            size={15}
            selectable={false}
            color={
              (colors?.[showMoreIconColor ?? showMoreColor] as
                | string
                | undefined) ??
              showMoreIconColor ??
              showMoreColor
            }
            style={{
              transform: [{ rotate: isShowingMore ? '180deg' : '0deg' }],
            }}
          />
        </Text>
      </View>
    )
  }

  return (
    <View sx={sx}>
      <Text
        sx={textSx}
        onPress={toggle}
        // numberOfLines={isShowingMore ? undefined : numberOfLines}
        // ellipsizeMode="clip"
      >
        {finalText}
      </Text>
      {renderShowMore()}
    </View>
  )
}
