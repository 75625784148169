import React from 'react'
import type { Sx as SxStyleProp } from 'dripsy'
import { View } from 'dripsy'

interface Props {
  sx?: SxStyleProp
  children?: React.ReactNode
  color?: string
}

const defaultProps: Props = {
  sx: {},
}

export type CardContentProps = Props & React.ComponentProps<typeof View>

const CardContent: React.FC<
  React.PropsWithChildren<CardContentProps>
> = React.forwardRef(function CardContent({ sx, children, ...props }, ref) {
  return (
    <View ref={ref} sx={{ padding: 3, ...sx }} {...props}>
      {children}
    </View>
  )
})

CardContent.defaultProps = defaultProps

export default CardContent
