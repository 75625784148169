import React from 'react'
import { View, Text } from 'dripsy'
import { getDisplayTimezone } from '../context/timezone/get-time-zone'

type Props = {
  timezoneName?: string
} & React.ComponentProps<typeof View>

export default React.memo(function Timezone(props: Props) {
  const { timezoneName, ...rest } = props

  const timezone = React.useMemo(
    () => timezoneName && getDisplayTimezone(timezoneName),
    [timezoneName]
  )

  if (!timezone) return null

  return (
    <View {...rest}>
      <Text>
        {timezone.name?.replaceAll('_', ' ')},{' '}
        <Text sx={{ color: 'mutedText' }}>
          {timezone.alternativeName} ({timezone.abbreviation})
        </Text>
      </Text>
    </View>
  )
})
